<template>
  <div class="about-div">
    <h1 class="about-title">Définition d’occupation du sol</h1>
    <p class="about-text">
      L’occupation du sol peut se définir comme la couverture physique de la surface terrestre (à l’état naturel ou transformé) et se traduit par le type d’usage qu’en fait l’Homme. Des bases de données « Occupation des Sols » (OCS) à intervalle de temps régulier permettent la connaissance et le suivi de cette couverture en cartographiant les différents types de milieux selon une emprise donnée, complétés par des informations fonctionnelles. 
      Il s’agit donc d’une composante indispensable aux outils d’aide à la décision, d’analyse et de suivi de la consommation des espaces, permettant de répondre aux différents enjeux règlementaires en la matière (loi Grenelle I et II, LMA, ALUR et loi pour la reconquête de la biodiversité, de la nature et du paysage de 2016). 
    </p>
    <h2 class="about-title">Un outil d’analyse, de suivi de la consommation des espaces et d’aide à la décision</h2>
    <p class="about-text">Le recours à une AMO durant plusieurs mois (2017-2018) a permis d’identifier les besoins: <br/>
      <ul>
        <li>régionaux (SRADDET, observatoire foncier, observatoire de la biodiversité, etc.)</li>
        <li>et locaux (SCOT, PNR, Agglomérations, Agences d’urbanisme, Départements, etc.)</li>
      </ul>
      de l’ensemble des acteurs du territoire et de produire une base de données « Occupation des sols »
      Grand Est à grande échelle (OCS GE2) avec un référentiel commun en matière de connaissance et
      suivi de l’occupation des sols.
    </p>
    <h2 class="about-title">Un projet d’envergure cofinancé</h2>
    <p class="about-text">Le montant global du projet est évalué à hauteur de 2 800 000 € maximum. Le projet est cofinancé par l'Etat et la Région à hauteur de 50% déduction faite des subventions européennes, soit:
      <ul>
        <li>37 % pour le FEDER</li>
        <li>31.5 % pour la Région</li>
        <li>31.5 % pour l'état</li>
      </ul>
    </p>
    <!-- <v-img class="about-img" :src="require('@/assets/images/bandeau_perma.png')" height="50vh"></v-img> -->
    <div class="about-faq">
      <h2 class="about-faq-title">Les spécifications</h2>
      <!-- FAQ section is made with Vuetify expansion-panel -->
      <v-expansion-panels class="about-faq-questions" multiple>
        <v-expansion-panel v-for="(question, i) in questions" :key="i">
          <v-expansion-panel-header style="font-weight: bold; font-size: large">{{ question.title }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            {{ question.answer }}
            <div v-if="question.links">
              <p v-for="link in question.links" :key="link"><a :href="link" target="__blank">{{ link }}</a></p>
            </div>
            <div v-if="question.imgs">
              <v-img :key="img" v-for="img in question.imgs" :src="require('@/assets/images/about/' + img)" width="40%"></v-img>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header style="font-weight: bold; font-size: large">Usages, téléchargements et conditions d’utilisation</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>
              Retrouvez sur la <a target="__blank" href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol">page projet OCS GE2</a> les cas d’usage et de réutilisation.  
              Les données sont ouvertes (Opendata) sous licence Etalab V2. Elles sont disponibles au téléchargement (en format cartographique et csv) à l'adresse suivante : <a target="__blank" href="https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol">https://www.datagrandest.fr/portail/fr/projets/occupation-du-sol</a>
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header style="font-weight: bold; font-size: large">Calculs affichés</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Les statistiques disponible sur <a href="/explore">explorer</a> sont consultable <a download href="OCSGE2_description_indicateurs_plateforme_version2_avril2022.pdf">ici</a></p>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header style="font-weight: bold; font-size: large">Manuel Utilisateur</v-expansion-panel-header>
          <v-expansion-panel-content>
            <p>Consulter le manuel utilisateur pour <a href="/explore">explorer</a> l’OCS GE2 : <a download href="manuel_utilisateur.pdf">Télécharger le manuel utilisateur</a></p>
            <p>Les <a target="__blank" href="https://www.datagrandest.fr/geonetwork/srv/fre/catalog.search#/metadata/FR-200052264-Commune_Grand_Est">sources des délimitations administratives</a> sont BD ADMIN-EXPRESS 2018 (selon le contenu administratif 2020). Rendez-vous sur le <a href="https://www.datagrandest.fr/geonetwork/srv/fre/catalog.search#/search?resultType=details&sortBy=relevance&fast=index&_content_type=json&from=1&to=20&any=OCSGE2" target="__blank">Catalogue DataGrandEst</a> pour consulter toutes les métadonnées de l’OCS GE2.</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  data: function () {
    return {
      questions: [
        /* {
          title: "Les données de référence",
          answer:
            "La production, ainsi que l’actualisation, d’un référentiel partagé en Grand Est a été identifiée comme une priorité au sein de la dynamique GéoGrandEst (futur DataGrandEst) et ce, dès la création de la Région en 2016. La production partagée d’une base de données Occupation des sols Grand Est à grande échelle (OCS GE2) a pour objectif de mettre à disposition de l’ensemble des acteurs du territoire un référentiel précis, homogène et commun en matière de connaissance et suivi de l’occupation des sols. La base de données Occupation des sols Grand Est à grande échelle (OCS GE2) est réalisée sous la maîtrise d’ouvrage de la Région Grand Est et cofinancée par l’Etat avec le soutien de subventions européennes. L’ensemble des acteurs du territoire a été associé à la définition des spécifications et des produits de valorisation. ",
        }, */
        {
          title: "La nomenclature",
          answer:
            "La nomenclature OCS GE2 est structurée en 4 niveaux emboités décrivant l’occupation du sol et complété par un 5 ème niveau décliné uniquement pour les territoires artificialisés. La présentation, le dictionnaire et la charte graphique de la nomenclature OCS GE2 sont accessibles en téléchargement depuis la plateforme GeoGrandEst :",
          links: [
            "https://www.datagrandest.fr/portail/sites/default/files/nomenclature_finale_ocs_ge2_vf.pdf"
          ],
          imgs: [
            'nomenclature_niv1.png',
            'nomenclature_niv2.png',
            'nomenclature_niv3.png',
            'nomenclature_niv4.png',
            'nomenclature_niv5.png'
          ]
        },
        {
          title: " Les précisions cartographiques",
          answer:
            "Base de données de l'occupation du sol (OCSGE2 Grand Est) a été créée à partir d'une orthophotographie aérienne panchromatique à 20 cm de résolution, à l'échelle du 1/3 000ème sur le non-artificialisé et au 1/2 000ème sur les secteurs artificialisés.",
        },
      ],
    };
  },
};
</script>

